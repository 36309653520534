.footer {
    /* height: 38vh; */
    /* background-image: linear-gradient(352deg, rgba(0, 0, 0, 0), rgba(192, 220, 255, .5)), linear-gradient(94deg, #c9c9c9, #fff); */
    /* background-color: white; */
    flex-direction: column;
    /* justify-content: center; */
    /* margin: 15px; */
    padding: 10px;
    padding-left: 30px;
    padding-right: 90px;
    display: flex;
    /* height: 552px; */
    position: relative;
}
.footer .peacock {
    width: 350px;
    position: absolute;
    right: -70px;
    top: -20px;
    z-index: 10;
    transform: scaleX( -1);
    
}

.footer__top {
    padding: 30px 30px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* background-color: red; */
}

.footer__flex {
    display: flex;
    height: 100%;
    gap: 30px;
}

.footer__item {
    flex-basis: calc(33% - 20px);
    /* background-color: aqua; */
    

}
.footer__item1 {
    display: flex;
    /* flex-basis: calc(25% - 30px); */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    /* height: 100px; */
    /* background-color: red; */
    /* border-bottom: 3px solid var(--line); */
    /* border-color: rgba(161, 131, 175, 1); */
    
}
.footer__item2 {
    display: flex;
    flex: 1;
    /* flex-basis: calc(25% - 30px); */
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
    padding-bottom: 20px;
    /* align-self: flex-end; */
    /* height: 100px; */
    /* background-color: red; */
    /* border-bottom: 3px solid var(--line);
    border-color: rgba(161, 131, 175, 1); */
    
}

.footer__item img {
    display: block;
    /* margin-bottom: 8px; */
}

.footer__pvt {
    margin-bottom: 10px;
    color: var(--primaryText);
    font-size: 16px !important;
}
.footer__pvt2 {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: Montserrat, sans-serif!important;
    color: var(--primaryText);
    font-weight: 100;
}

.footer__item h4 {
    font-size: 23px !important;
    color: var(--heading);
    /* border-bottom: 1px solid var(--line); */
    text-decoration: underline;
    
    /* textDecorationLine: 'underline', */
}
.footer__item .footer-menu-title{
/* background-color: aliceblue; */
/* justify-content: center;
align-items: center; */
padding-top: 6px;
align-content: center;
}
.footer__item .footer-menu1 .footer-text{
    /* background-color: aliceblue; */
    /* justify-content: center;
    align-items: center; */
    /* padding-top: 6px;
    align-content: center; */
    font-size: 16px !important;
    /* color: var(--heading); */
    /* width: 109%; */
    }
    .footer__item .footer__item_head1 .footer-menu1{
    /* background-color: red; */
    /* justify-content: center;
    align-items: center; */
    /* padding-top: 6px;
    align-content: center; */
    width: 100%;
    }
.footer__item .footer__item_head{
    display: flex;
    /* align-content: center !important;
    align-items: center !important;
    align-self: center; */
    /* background-color: aquamarine; */
}
.footer__item .footer__item_head1{
    display: flex;
    /* align-content: center !important;
    align-items: center !important;
    align-self: center; */
    margin-left: -70px;
    /* background-color: aquamarine; */
    /* width: auto; */
}
.footer-flex {
    display: flex;

    flex-direction: column;
}

.footer-flex>* {
    margin-bottom: 20px !important;
}

.footer__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
}
.footer__item .footer-menu-item {
    /* text-align: center; */
    color: var(--primaryText);
    font-size: 14px;
     justify-content: center;
    align-items: center;
    align-content: center;
}
.footer-menu-item2 {
    /* text-align: center; */
    display: flex;
    color: var(--primaryText);
    font-size: 16px;
    flex-direction: row;
     /* justify-content: center; */
    /* align-items: center;
    align-content: center; */

    /* background-color: red; */
}
.footer-menu-item2 .icon {
    /* text-align: center; */
    color: var(--primaryText);
    font-size: 30px;
     justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 20px;
    /* background-color: red; */
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

@media (max-width: 900px) {
    .footer {
        height: auto;
        width: 100%;
        overflow: hidden;
    }
    .footer__top {
        flex-direction: column;
    }

    .footer .peacock {
    width: 320px;
    position: absolute;
    right: 0px;
    left: 160px;
    top: 0px;
    /* padding-left: 200px; */
    z-index: 9;
    transform: scaleX( -1);
    
}
    
    .footer__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 0;
    }
    .footer__item .footer__item_head1{
        display: flex;
        /* align-content: center !important;
        align-items: center !important;
        align-self: center; */
        margin-left: 0px;
        /* background-color: aquamarine; */
        /* width: auto; */
    }
    .footer__pvt {
        font-size: 20px;
        font-weight: 500;
        color: var(--primaryText);
    }

    .footer__item .footer-menu {
        margin: 0;
        color: var(--primaryText);
    }

    .footer__item .footer-menu-item {
        text-align: center;
        color: var(--primaryText);
    }
}