.top-bar {
    height: 15vh;
    /* background-color: rgba(0, 0, 0, 0); */
    /* background-color: red !important; */
    padding-left: 30px;
    padding-right: 30px;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-bottom: 0px;
    box-shadow: none !important;
    /* background-image: url(../../images/BG2.png); */
    /* box-shadow: -5px 6px rgba(0, 0, 0, 0.25) !important ; */
}
.top-bar_active {
    height: 15vh;
    /* background-color: rgba(0, 0, 0, 0); */
    /* background-color: red !important; */
    padding-left: 30px;
    padding-right: 30px;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-bottom: 0px;
    box-shadow: none !important;
      background-image: url(../../images/BG2.png);
     
    /* box-shadow: -5px 6px rgba(0, 0, 0, 0.25) !important ; */
}

.top-bar_active  .logo-link {
    height: 100%;
    z-index: 110;
    /* background-color: #4F1C67;  */
}

.top-bar_active  .logo-link .logo {
    height: 100%;
    padding: 5px 0;
}
.top-bar_active  .logo-link .logo-hide{
    display: none;
}
.top-bar_active  .logo-link .footer__pvt {
    font-size: 30px;
    font-weight: 500;
    /* font-family:"Poppins" */
}
.top-bar  .logo-link {
    height: 100%;
    z-index: 110;
}

.top-bar .logo-link .logo {
    height: 100%;
    padding: 5px 0;
    /* background-color: #4F1C67;  */
}
.top-bar .logo-link .logo-hide {
display: none;
}
.top-bar .logo-link .footer__pvt {
    font-size: 30px;
    font-weight: 500;
    /* font-family:"Poppins" */
}

.top-bar .hero_button {
    /* width: 90%!important; */
    /* background-color:  var(--line) !important; */
    /* border-width: 1px !important; */
    border-radius: 10px;
    border-color: var(--line) !important;
    border: 3px solid var(--line);
}
.top-bar .hero_button button {
    width: 100%!important;
    font-weight: 600;
    color: var(--primaryText);
}
.top-bar .block2 {
    /* background-color:#ffffff; */
    
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    padding: 0%;
    margin-bottom: 0px !important;
    /* border-width: 10px !important; */
    /* border-color: #ffffff !important; */
    /* padding-left: 2px; */
    /* border-left: 1px solid var(--line); */
   
}
.top-bar .rowblock2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  
    /* height: 14px; */
    /* overflow: hidden; */
    /* background-color: #642525; */
}
.top-bar .block2 .text {
    /* background-color:#ffffff; */
    
   color: var(--primaryText);
   font-size: 18px;
   /* padding-inline: 20px; */
   padding-left: 5px;
   padding-right: 20px;
   
   font-family: Montserrat, sans-serif!important;
}


.top-bar_active .hero_button {
    /* width: 90%!important; */
    border-radius: 10px;
    border-color: var(--line) !important;
    border: 3px solid var(--line);
}
.top-bar_active .hero_button button {
    width: 100%!important;
    font-weight: 600;
    color: var(--primaryText);
}
.top-bar_active .block2 {
    /* background-color:#ffffff; */
    
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
    /* border-width: 10px !important; */
    /* border-color: #ffffff !important; */
    /* padding-left: 2px; */
    /* border-left: 1px solid var(--line); */
   
}
.top-bar_active .rowblock2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* height: 14px; */
    /* overflow: hidden; */
    /* background-color: #642525; */
}

.top-bar_active .block2 .text {
    /* background-color:#ffffff; */
    
   color: var(--primaryText);
   font-size: 18px;
   /* padding-inline: 20px; */
   padding-left: 5px;
   padding-right: 20px;
   
   font-family: Montserrat, sans-serif!important;
}

.header-layout  {
    /* height: vh; */
    background-color: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 30px;
    border: #ffffff;
    margin-top: 0px !important;
    /* background-color: #642525; */

 
}
.header-layout .menu {
    /* height: vh; */
    /* background-color: red !important; */
    /* box-shadow: none !important;
    border: 0 !important;
    padding: 0 30px;
    border: #ffffff;
    margin-top: 0px !important; */
    /* background-color: #642525; */
    overflow: hidden;

 
}
.header-layout.active {
   
    background-color: var(--primary) !important;
    box-shadow: none !important;
  
}


.header-layout .item {
    text-transform: uppercase !important;
    /* color: var(--secondary) !important; */
    height: 100%;
    /* color: var(--primary); */
    /* text-transform: uppercase; */
    font-family: Montserrat, sans-serif!important;
    font-size: 14px;
    font-weight: 500!important;
    line-height: 18px;
    vertical-align: top!important;
    color:  var(--primaryText) !important;
    text-align: left;
    /* margin-left: auto;
    margin-right: auto; */
    padding-right: 20px!important;
    
    padding-top: 0px;
    text-decoration: none;
    /* display: inline-block; */
    /* position: relative; */
    /* align-items: flex-start!important; */
    box-shadow: none !important;
    
}

.header-layout .item::before {
    content: '';
    background-color: transparent !important;
    box-shadow: none !important;
}

.header-layout .right {
    align-items: center !important;
    box-shadow: none !important;
}


.header-layout .item.active {
    background-color: transparent !important;
    border-bottom: 3px solid var(--line);
    color:var(--primaryText) !important;
    height: 94%;
    font-weight: 600!important;
    box-shadow: none !important;
}

.header-layout .right.menu .item:hover {
    font-weight: 800 !important;
    color: var( --primaryText) !important;
    /* color: red !important; */
    box-shadow: none !important;
}

.header-layout__image-link:link,
.header-layout__image-link:visited,
.header-layout__image-link {
    cursor: pointer;
    border-left: 0 !important;
    padding-left: 0 !important;
    height: 100%;
    object-fit: cover;
    box-shadow: none !important;
}

.header-layout__image-link:hover,
.header-layout__image-link:active {
    background-color: transparent !important;
    box-shadow: none !important;
}


.header .nav-bar-open {
    display: flex !important;
    box-shadow: none !important;
}
.tab-style {
    color:#642525;
    background-color: red;
}


.header .nav-bar-hide {
    display: flex !important;
    /* background-color: aliceblue; */
}
.tab-style {
    color:#642525;
    background-color: red;
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */







  
@media (max-width: 900px ) {

    .top-bar {
   height: 10vh;
   position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    display: flex;
    width: 100% !important;
    /* background-color: #4F1C67; */
        /* background-image: url(../../images/BG2.png); */
     
      /* box-shadow: -5px 6px rgba(0, 0, 0, 0.25) !important ; */
  }
  .top-bar_active {
    height: 10vh;
    position: fixed;
     top: 0%;
     bottom: auto;
     left: 0%;
     right: 0%;
     display: flex;
     width: 100% !important;
     /* background-color: #4F1C67;  */
         background-image: url(../../images/BG2.png);
      
       /* box-shadow: -5px 6px rgba(0, 0, 0, 0.25) !important ; */
   }
    .mobile-bar {
        display: flex;
        z-index: 110;

    }  
    .mobile-bar2 {
        display: flex;
        z-index: 110;
        /* padding-bottom: 30px; */

    } 

    .top-bar_active .nav-bar-hide {
        display: none !important;
        /* background-color: red !important; */
    }
    .top-bar .nav-bar-hide {
        display: none !important;
        /* background-color: red !important; */
    }

    .top-bar_active .nav-bar-open {
        display: flex !important;
    }
    .top-bar .nav-bar-open {
        display: flex !important;
    }
    .top-bar .rowblock2hide {
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        display: none !important;
        /* padding-top: 100px; */
        /* height: 14px; */
        /* overflow: hidden; */
        /* background-color: #642525; */
    }
    .top-bar_active .rowblock2hide {
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        display: none !important;
        /* padding-top: 100px; */
        /* height: 14px; */
        /* overflow: hidden; */
        /* background-color: #642525; */
    }
    .ui stackable header-layout menu{
        margin-top: 100px;
        background-color: #642525;
    }
  
    .top-bar .rowblock2 .block2 {
        /* background-color:#ffffff; */
        
        display: flex;
        flex-direction: row;
        align-content: center;
        /* justify-content: flex-end; */
        align-items: center;
        padding: 0%;
        margin-bottom: -30px !important;
    
        z-index: 100;
    }
    .top-bar_active .rowblock2 .block2 {
        /* background-color:#ffffff; */
        
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        padding: 0%;
        margin-bottom: -30px !important;
       
    }
    .top-bar .hero_button button {
        width: auto!important;
        font-weight: 400;
        /* color: #4F1C67; */
    }
    .top-bar .hero_button {
        /* display: none; */
        width: 160px!important;
        margin-right: 10px;
       
    }
    .top-bar_active .hero_button button {
        width: auto!important;
        font-weight: 400;
        /* color: #4F1C67; */
    }
    .top-bar_active .hero_button {
        /* display: none; */
        width: 160px!important;
        margin-right: 10px;
       
    }
    .top-bar .ui.stackable.menu .right.menu {
        background-color:white;
        flex-direction: column;
        position: absolute;
        top: 9vh;
        left: 0;
        width: 100vw;
        transition: all 1s ease-in-out;
        overflow: hidden;
        /* margin-top: 100px; */
    }
    .top-bar_active .ui.stackable.menu .right.menu {
        background-color: var(--primary);
        flex-direction: column;
        position: absolute;
        top: 9vh;
        left: 0;
        width: 100vw;
        transition: all 1s ease-in-out;
        overflow: hidden;
        /* margin-top: 100px; */
    }
    .header-layout  {
        /* height: vh; */
        background-color: transparent !important;
        box-shadow: none !important;
        border: 0 !important;
        padding: 0 00px;
        border: #ffffff;
        margin-top: 0px !important;
        /* background-color: #642525; */
    
     
    }
    
    .header-layout .menu {
        display: none;
      
    
     
    }
    .header-layout .item {
        height: 25%!important;
        box-sizing: border-box;
    }
    .top-bar .logo-link .footer__pvt {
        font-size: 30px;
        font-weight: 500;
        font-family:"Poppins"
    }
    .logo-link {
        width: 80%;
    }

    .logo-link .logo {
        width: 60%;
        object-fit: contain;
    }

    i.big.icon {
        color: var(--line);
    }

    .block2 {
        border: 0px !important;
        padding: 2px;
    }

    .top-bar .block2 .text {
        /* background-color:#ffffff; */
        
       color: var(--primaryText);
       font-size: 18px;
       /* padding-inline: 20px; */
       padding-left: 5px;
       padding-right: 4px;
       
       font-family: Montserrat, sans-serif!important;
    }
}

@media (min-width: 901px) {
    .mobile-bar {
        display: none;
    }
}