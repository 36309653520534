@import "~react-image-gallery/styles/css/image-gallery.css";
:root {
  --primary: #4D44B5;
  --primaryText:#4F1C67;
  --secondary: #474c68;
  --active-link: #0082f3;
  --active-tab: #8abaf1;
  --active-tab-border: #e6781f;
  --white: #ffffff;
  --line:#9747FF;
  --active-tabM: #ffffff;
  --heading:#A1238E;
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, Lexend Tera;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary);
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.primary.button {
  background-color: var(--primary1) !important;
}

@media (min-width: 900px) {
    .mobile-bar {
        display: none;
    }

    .body {
      height: 100vh;
    }
}