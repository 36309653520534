* {
  scroll-behavior: smooth;
}


.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Uparrow {
  color: var(--primary);
  background-color: var(--white);
  position: fixed;
  right: -100px;
  bottom: 50px;
  font-size: 37px !important;
  z-index: 50;
  cursor: pointer;
  transform: translateX(-150px);
  animation: slideLeft 1s;
  padding: 3px;
  border-radius: 50%;
}
.UparrowHiden {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(-150px);
  }
}