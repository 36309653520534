.box_div {
  height:100%;
  justify-content: center;
  align-content:center;
  /* width:100%; */
  align-items:'center';
  /* background-color: red; */
  
}

.box{
  position: 'absolute' as 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  height:'100%';
  justify-content: 'flex-end';
  align-items: 'flex-end';
  /* background-color: antiquewhite; */
  p: 4;
}

@media (max-width: 900px ) {
  .box_div {

    justify-content: center !important;
    align-content:center !important;
    width:100%;
    align-items:'center' !important;
    
    /* background-color: red; */
    
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    /* background-color: aliceblue; */
    display: flex;
    align-self: center !important;
    padding-top: 20px;
    justify-self: center !important;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
  }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    display: flex;
    object-fit: contain;

    align-self: center !important;
    /* padding-top: 20px; */
    justify-self: center !important;
    align-items: center !important;
    align-content: center !important;
    /* background-color: aqua; */
}
}