.ui.grid>[class*='four wide'].column {
  width: 30% !important;
}

.ui.grid>[class*='twelve wide'].column {
  width: 70% !important;
}

.mobile__top {
  display: flex;
  /* height: 52vh; */
  /* height: calc(100vh / 2); */
  /* background-position: 100% 50%, 0px 0px, 0px 0px;
  background-size: 960px, auto, auto;
  background-repeat: no-repeat, repeat, repeat; */
  height: 70vh;
  padding-top: 90px;
  flex-direction: row;
  /* background-color: red; */
}

.mobile__top .AcPic{
  width: 50%;
  margin-left: -100px !important;
  margin-bottom: -50px !important;
  height: 100%;
  align-self: flex-end;

}
.mobile__top .icon1{
   width: 150px;

  
  align-self:flex-start;

}
.mobile__top .icon2{
  /* width: 0%; */

  height: 100%;
  align-self: flex-end;

}

.mobile__top h1 {
  /* height: calc(100vh / 2) !important; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 43px !important;
  color: var(--primaryText) !important;
  font-family:    "Lexend Tera", sans-serif !important;
  margin-left: -100px !important;
  align-self: center;
  /* margin-top: 70px !important; */
  /* background-color: #fff; */
}

.mobile__bottom {
  /* padding-top: 100px !important; */
  /* height: auto; */
  /* padding-bottom: 30px !important; */
  border-width: 0px !important ;
  
}
.mobile__bottom .main_section2 {
  background-image: url("../../images/BG2.png");
  width: 100%;
  min-height: 50vh;
  margin-top: -4px;

  img {
      width: 45%;
  }

  .content {
      width: 100%;
  }

  h3 {
      color: #4f1c67;
      font-family: Lexend Tera;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      z-index: 100;
  }

  p {
      color: #000;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
  }

  .sub_section {
      width: 85%;
      margin-left: 7.5%;
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      align-items: center;
  }

  .cultural_Section {
      padding-bottom: 50px;
      img {
          width: 20%;
      }
  }

  .arts_section {
      img {
          width: 37%;
      }

      .content{
          position: relative;
  
          .lottie{
              width: 150px;
               /* height: 110px; */
              position: absolute;
              transform: rotate(-35deg);
              top:-60px;
              left:-90px;
          }

          .wheel{
              position: absolute;
              width: 300px;
              position: absolute;
              /*  top:-60px; */
              left:-170px;
          }
      }
  }
}






/* ///// */
.mobile__bottom .bms-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 50px;
  /* height: 44vh; */
  /* background-color: black; */
}

.mobile__bottom .bms-section .bms-item {
  /* width: 24%; */
  /* height: 100vh; */
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  margin-bottom: 30px;
  width: 100%;
  padding-left:20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.mobile__bottom .bms-section .bms-item .title {
  /* height: 12%; */
  /* width: 100%; */
  font-size: 32px !important;
  color: var(--white) !important;
  /* border-bottom: 2px solid var(--white) !important; */
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-weight: 600;
  padding-left: 20px;
}

.mobile__bottom .bms-section .bms-item .sub-title {
  /* height: 30%; */
  font-size: 26px !important;
  color: var(--white) !important;
  margin-top: 0px !important;
  margin-bottom: 8px !important;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile__bottom .bms-section .bms-item .specification {
  height: 30%;
  color: var(--white) !important;
  padding-left: 20px;
  margin-top:20px ;
}
.mobile__bottom .bms-section .bms-item .specification h4 {
  /* height: 30%; */
  color: var(--white) !important;
  line-height: 30px;
}

.mobile__bottom .bms-section .bms-item .description {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--white) !important;
}
.mobile__bottom .bms-section .bms-item .description h4 {
  /* height: 30%; */
  color: var(--white) !important;
}

.mobile__bottom .item {
  padding-right: 0 !important;
}
.mobile__bottom .menu  {
  /* background-color: var(--white) !important; */
  /* margin-top: 30px !important; */
  flex-wrap: wrap;
 border-width: 0px !important ;
  margin-bottom: 30px !important;
  padding: 0 2em;
}

.mobile__bottom .menu .item1 {
  /* background-color: var(--white) !important; */
  color: var(--white) !important;
  display: flex;
  justify-content:space-between;
  align-items: flex-start;
  /* text-transform: uppercase !important; */
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 700;
  border-radius: 0 !important;
  /* padding-top: 20px !important; */
  padding-left: 32px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  /* border-right: 5px solid transparent !important; */
  height: 69px;
}
.mobile__bottom .menu .item1.disabled {
  /* background-color: var(--white) !important; */
 width: 100%;
 justify-content:flex-start !important;
 color: var(--white) !important;
 font-size: 20px !important;
 padding-top: 5px !important;
 padding-bottom: 5px !important;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  height: 30px;
}
.mobile__bottom .menu .item1.disabled:hover {
  /* background-color: var(--white) !important; */
 width: 100%;
 justify-content:flex-start !important;
 color: var(--white) !important;
 /* font-size: 20px !important; */
 padding-top: 5px !important;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  /* height: 30px; */
}
.mobile__bottom .menu .item1 .icon {
  /* background-color: red; */
  margin-right: 10px!important;
  /* height: 7vh; */
  /* width: 7vh; */
}

.mobile__bottom .menu .item1:first-child {
  border-radius: 0 !important;
}

.mobile__bottom .menu .item1:last-child {
  border-radius: 0 !important;
}

.mobile__bottom .menu .item1.active {
  background-color: transparent !important;
  border-radius: 0 !important;
  /* transform: translateX(20px) !important; */
  z-index: 1;
  /* height: vh; */
  border-width: 0px !important;
  /* margin-bottom: 10px !important; */
}



.mobile__bottom .twelve {
  padding-left: 0 !important;
  /* margin-top: -50px !important; */
}

.mobile__bottom .twelve .ui.bottom.attached.segment {
  padding: 20px 25px !important;
  background-image: linear-gradient(355deg,
      transparent,
      rgba(192, 220, 255, 0.5)),
    linear-gradient(94deg, #c9c9c9, #fff) !important;
}

.mobile__bottom h1 {
  font-size: 21px !important;
  padding-left: 2em;
}

.mobile__bottom {
  margin-top: 0px;
  /* padding: 0 2em; */
}


.mobile__bottom .tab { 
  /* height: 51vh; */
  background-image: url("../../images/BG2.png");
  /* backgroundSize:'cover',
  background: 'cover'  */
  padding-left: 20px;
  padding-right: 20px;
  border-width: 0px !important ;
  /* min-height: 101vh; */
background-size: cover;
background: cover;
}

.mobile .mobile-description {
  height: 100vh;
  width: 100vw;
  /* background-image: url("../../images/bms-description.png"), linear-gradient(#e5e7e7, #e5e7e7); */
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile .battery-swapping {
  height: 100vh;
  width: 100vw;
  /* background-image: url("../../images/battery-swapping.jpg"); */
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile .beta-schematic {
  height: 100vh;
  width: 100vw;
  /* background-image: url("../../images/beta-schematic.jpg"); */
  background-repeat: no-repeat;
  background-size: contain;
}

.tab h2 {
  font-size: 32px !important;
  color: var(--white) !important;
  border-bottom: 2px solid var(--white) !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.tab h3 {
  font-size: 28px !important;
  color: var(--white) !important;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.tab .tab__flex {
  display: flex;
  flex-basis: 100%;
}

.tab .tab__flex>* {
  flex-basis: 50%;
}

.tab .tab__flex .tab__flex__left h4 {
  color: var(--white) !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
}

.tab__flex__right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  animation: mobile-img 1s forwards;
}

@keyframes mobile-img {
  0% {
    right: 0px;
  }

  100% {
    right: 100px;
  }
}

.tab__flex__images img {
  flex-direction: column;
  width: 100px;
  padding: 5px;
  object-fit: cover;
}

.tab__btn__flexM {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 50%;
}
.tab__btn__flexM .icon{
 /* height: 100%; */
 width: 30%;
}
.tab__btn__flexM .icon2{
  /* height: 100%; */
  width: 70%;
 }





@media (max-width: 900px) {
  .mobile__top {
    height: 25vh;
  }

  .mobile__top h1 {
    font-size: 30px!important;
    height: 25vh!important;
  }


  .mobile__bottom .main_section2 p {
    color: #000;
    /* font-family: Open Sans; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.mobile__bottom .main_section2   h3 {
  color: #4f1c67;
  font-family: Lexend Tera;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 100;
}

  .mobile__bottom .main_section2 {
    background-image: url("../../images/BG2.png");
    width: 100%;
    min-height: 50vh;
    margin-top: -4px;
  
    img {
        width: 90%;
    }
  
    .content {
        width: 90%;
    }
  
  

  
    .sub_section {
        width: 85%;
        margin-left: 7.5%;
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        align-items: center;
        flex-direction: column-reverse;
    }
  
    .cultural_Section {
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        img {
            width: 90%;
        }
    }
  
    .arts_section {
      display: flex;
    flex-direction: column;
        img {
            width: 90%;
        }
  
        .content{
            position: relative;
    
            .lottie{
                width: 150px;
                 /* height: 110px; */
                position: absolute;
                transform: rotate(-35deg);
                top:-60px;
                left:-90px;
            }
  
            .wheel{
                position: absolute;
                width: 300px;
                position: absolute;
                /*  top:-60px; */
                left:-170px;
            }
        }
    }
  }

  .mobile__bottom {
    height: auto;
    margin-top: 0;
    width: auto!important;
    padding: 0!important;
    /* overflow-x: scroll; */
  }

  .mobile__bottom .ui.grid {
    flex-direction: column;
    width: auto!important;
  }

  .ui.grid>[class*='four wide'].column {
    width: 100% !important;
  }

  .ui.grid>[class*='twelve wide'].column {
    width: 100%!important;
  }

  .mobile__bottom .twelve .ui.bottom.attached.segment {
    width: auto!important;
    padding: 0!important;
    overflow: hidden;
  }

  .mobile__bottom .menu {
    display: flex;
    flex-direction: row!important;
    overflow-y: auto;
    width: 100vw!important;
    /* scrollbar-width: none!important; */
  }

  .bms-section {
    /* flex-direction: column!important; */
    /* overflow-x: scroll; */
    /* width: 400vw!important; */
    /* background-image: linear-gradient(355deg, transparent, rgba(192, 220, 255, 0.5)), linear-gradient(94deg, #c9c9c9, #fff) !important; */
    /* margin-top: -2vh; */
    /* margin-bottom: -2vh; */
    margin-left: 3vh;
    height: 51vh!important;
    /* overflow: hidden; */
  }

  .bms-item {
    /* width: 80vw!important; */
    padding-top: 1vh;
    /* margin-top: 2vh; */
    flex-direction: column!important;
  }

  .tab {
    height: 70vh !important;
    overflow-x: auto;
    min-height: 50vh!important;
    overflow-y: hidden;
    padding-bottom: 2vh;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: 2vh;
    scrollbar-width: 0;
  }

  .tab .ui.large.header {
    margin-left: 4vh!important;
  }

  .tab__btn__flexM {
    width: 100%;
  }

  .tab .tab__flex {
    margin-left: 4vh!important;
    height: 60vh!important;
  }

  .mobile .mobile-description {
    height: 220px!important;
  }

  .mobile .battery-swapping {
    height: 220px!important;
  }

  .mobile .beta-schematic {
    height: 220px!important;
  }

  .tab__btn__flex {
    width: auto !important;
    height: 300px;
  }

  .bms-item .icon2{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .tab__btn__flex .bms-item .icon{
    object-fit: contain;
  }
}