.about-title2 {
  height: 35vh;
  background-image: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex; 
  padding-left:200px ;
  /* margin-left: -100px; */
 
}

.about-title2 h1 {
  font-size: 46px!important;
  color: var(--primaryText) !important;
  font-family:    "Lexend Tera", sans-serif ;
}

.about {
  /* background-image: linear-gradient(94deg, #c9c9c9, #fff); */
  background-color:rgba(70, 61, 163, 1);
  min-height: 100vh;
  padding-top: 50px;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-content .about-item1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* width: 100%; */
  padding: 0 25px ;
  margin-bottom: 30px;
  /* padding-top: 200px; */
  /* background-color: red; */
}

.about-item .lottie {
  height: 55vh!important;
}

.about-item .lottie-section {
  /* width: 50%; */
  height: 100%;
  /* background-color: red; */
}
.about-content .about-item1 .lottie-sectionImg1 {
  width: 50% !important;
  /* background-color: red; */
  /* height: 100%; */
  border-radius: 20px;
  /* overflow: hidden; */
 
}
.about-content .about-item1 .lottie-sectionImg1 .icon1{
  /* height: 100%; */
  display: flex;
  width: 100%;
  /* height: 100%; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* padding: 44px; */
  border-radius: 0px;
  /* background-color: red; */
 
}

.about-item1 .text-section {
  /* width: 50% !important; */
  /* background-color: red; */
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
} 

.about-item1 .why-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.about-item1 .left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding-right: 25px;
  height: 35vh;
}

.about-item1 .right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding-left: 25px;
  height: 35vh;
}

.about-item1 .item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 38px;
  color: var(--primaryText) !important;
  font-weight: 600;
}

.about-item1 h1 {
  text-align: center !important;
  /* color: var(--secondary) !important; */
  font-size: 43px !important;
  margin: 0;
  margin-bottom: 80px !important;
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
  color: var(--primaryText) !important;
  font-family:    "Lexend Tera", sans-serif ;
}

.about-item1 ul li {
  font-size: 16px !important;
  margin-bottom: 5px !important;
  color: var(--primaryText) !important;
}
.about-item1 > * {
  flex: 1;
}

.about-item1 img {
  width: 100%;
  display: block;
  border-radius: 100%;
}

.about-item1 h2 {
  /* color: var(--secondary) !important; */
  font-size: 38px !important;
  margin-bottom: 15px !important;
  color: var(--primaryText) !important;
  font-family:    "Lexend Tera", sans-serif ;
}
.about-item1 p {
  font-size: 16px !important;
  color: var(--primaryText) !important;
  line-height: 30px;
}

.about__top__right div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__middle__flex {
  display: flex;
  margin-bottom: 50px;
  gap: 30px;
}
.about__middle__flex > * {
  flex: 1;
}

.about__middle__flex h2 {
  /* color: var(--secondary) !important; */
  font-size: 38px !important;
  margin-bottom: 15px !important;
  text-align: center;
  color: var(--primaryText) !important;
}
.about__middle__flex p {
  font-size: 16px !important;
}



/* 0   - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800px] is where our normal styles apply
1800px +    :   Big Desktop */


@media (max-width: 900px) {

  .about-title2 {
    height: 20vh;
    width: 100%;
    /* background-color: antiquewhite; */
    justify-content: center !important;
    align-content: center;
    padding-left:0px !important;
    align-items: center;
    padding-top: 70px;
  }
  .about-title2 h1 {
    font-size: 34px!important;
    color: var(--primaryText) !important;
    font-family:    "Lexend Tera", sans-serif ;
    /* background-color: aqua; */
    width: 100% !important;
    text-align: center;
   
  }
  .about-item1 {
    width: 90%;
    /* background-color: red; */
    padding-left: 0px;
  }
  .about-item1 .text-section{
    width: 90%;
    /* background-color: blue; */
  }
  .about-item1 h2 {
    /* color: var(--secondary) !important; */
    font-size: 24px !important;
    margin-bottom: 15px !important;
    color: var(--primaryText) !important;
    font-family:    "Lexend Tera", sans-serif ;
    width: 90%;
    /* background-color: red; */
  }
  .about-item1 p {
    font-size: 12px !important;
    color: var(--primaryText) !important;
    line-height: 24px;
  }
  .about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .about-content .about-item1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    padding: 0 25px ;
    margin-bottom: 30px;
    /* padding-top: 200px; */
    /* background-color: red; */
  }
  
  .about-item1 {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 5vh;
  }
  .about-content .about-item1 .lottie-sectionImg1 {
    width: 90% !important;
    /* background-color: red; */
    /* height: 100%; */
    border-radius: 20px;
    margin-top: -20px;
    margin-bottom: -100px;
    /* overflow: hidden; */
   
  }
  .about-content .about-item1 .lottie-sectionImg1 .icon1{
    /* height: 100%; */
    display: flex;
    width: 100%;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* padding: 44px; */
    border-radius: 0px;
    /* background-color: red; */
   
  }
  .about-item1:first-child {
    padding-top: 5px;
  }

  .about-item1 .lottie-section {
    width: 100%;
    /* height: 50%; */
  }

  .about-item1 .lottie {
    height: auto!important;
  }

  .about-item1 .text-section {
    width: 100%;
    /* height: 50%; */
  }

  .about-item1:last-child {
    margin-bottom: 5vh;
  }
}