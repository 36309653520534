.hero {
    width: 100%;
    height: 101vh;
    /* background-image: linear-gradient(352deg,
            transparent,
            rgba(192, 220, 255, 0.5)),
        linear-gradient(94deg, #c9c9c9, #fff); */
}

.hero .hero__flex {
    display: flex;
    align-items: center;
    height: 100vh;
    background-size: 'cover';
    /* margin-top: 100px; */
    /* padding-bottom: 115px; */
}

.hero .hero__flex .hero_slider_wrapper {
    width: 100%;
    /* padding-top: 115px; */
    /* margin-top: 100px; */
    /* background-image: url("../../assets/nav_footer/images/footer_bg.png"); */
   

}

.modal_div {
    height:90vh;
    display: flex;
    justify-content: center !important;
    align-content:center !important;
    /* width:100%; */
    align-items:center !important;

    /* background-color: red; */
    
  }
  
 .modal{
    /* position: absolute as absolute; */
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    height:100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* background-color: antiquewhite; */
    /* p: 4; */
  }
  .modal .imgB{
    /* width: 100%; */
    height: 100%;
    background-size: contain;
    justify-content: center;
    align-items: center;

  }
  
.hero .hero__flex .hero_slider_wrapper .swiper {
    /* width: 100%; */
        height: 90vh;
        /* padding-top: 115px; */
    /* background-image: url("../../assets/nav_footer/images/footer_bg.png"); */
   

}
.hero .hero__flex .hero_slider_wrapper .swiper .slide1 {
    /* // background-image: url("../../assets/nav_footer/images/footer_bg.png"); */
    /* padding-top: 115px; */
    /* height: 100%; */
    /* width: 100vh; */
    /* overflow: hidden; */
    background-size: cover;
   
}
.hero .hero__flex .hero_slider_wrapper .swiper .slide2 {
    /* // background-image: url("../../assets/nav_footer/images/footer_bg.png"); */
    /* padding-top: 115px; */
    /* // height: 40vh; */
    overflow: hidden;
    /* height: 100%; */
    background-size: cover;
    
}

.hero .hero__flex .hero_slider_wrapper .swiper .slide2 .img {
    /* width: 100px !important;
    background-color: red; */
    width: 100%;
    /* height: 100%; */
    background-size: cover;
}
.hero .hero__flex .hero_slider_wrapper .swiper .slide1 .img {
    /* width: 100px !important; */
    width: 100%;
    /* height: 100%; */
    background-size: contain;
}


.hero .hero__flex .hero__left {
    width: 100%;
    height: 100%;
    /* object-fit: fill; */
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-content: center;
    align-items: center;
    /* margin-left: 0;
    margin-right: 0; */
    /* padding-bottom: 111px; */
    /* padding-left: 60px; */
    /* padding-right: 0; */
    display: flex;
    /* background-color: red; */
}

.hero .hero__flex .hero__right {
    z-index: 2;
    width: 67%;
    height: 100%;
    clear: none;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 145% 109%!important;
    flex: 0 auto;
    order: 0;
    align-self: center;
    margin-left: 0;
    margin-right: 0;
    position: static;
    overflow: visible;
    height: calc(100vh - 100px);
    background: no-repeat center / cover;
    position: relative;
    animation: hero-img-animation-one 1s forwards,
        hero-img-animation-two 3s alternate infinite;
}

.hero__left .header {
    color: var(--white) !important;
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
}
.hero__left .features {
    
    display: flex; 
    flex-direction: row; 
    width: 80%; 
    justify-content: space-between; 
    /* background-color: aliceblue; */
}
.hero__left .features2 {
    
    display: flex; 
    flex-direction: row; 
    width: 60%; 
    justify-content: space-between; 
    margin-Top: -20px;
    /* background-color: aliceblue; */
}

.hero__left p {
    /* color: var(--secondary) !important; */
    color: var(--white) !important;
    font-size: 30px;
}

/* .hero__flex>.hero__left {
    flex-basis: 40%;
    z-index: 1;
} */

.hero__left h1 {
    font-size: 40px !important;
    color: var(--white) !important;
    font-weight: 900 !important;
    font-family: Montserrat, sans-serif;
    box-shadow: none !important;
   
}
.hero__left h2 {
    font-size: 24px !important;
    color: var(--white) !important;
    /* font-weight: 900 !important; */
    /* background-color: blue; */
    /* text-align: center; */
    align-self: center !important;
    justify-content: center !important;
    padding-left: 10px !important;
    margin-top: 0px !important;
    align-content: center  !important;
    font-family: Montserrat, sans-serif;
}
.hero__left h3 {
    font-size: 77px !important;
    /* color: var(--primary) !important; */
    color: white;
    /* font-weight: 900 !important; */
    /* background-color: blue; */
    /* text-align: center; */
    align-self: center !important;
    justify-content: center !important;
    padding-left: 10px !important;
    margin-top: 0px !important;
    align-content: center  !important;
    font-family: Montserrat, sans-serif;
    box-shadow: none !important;
}

.hero .hero__flex .hero__left .hero_paragraph,
.hero_heading {
    display: flex;
    position: relative;
    animation: heading 1s forwards;
    /* color: var(--white) !important; */
    text-align: center;
    text-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25);
   
}
.hero .hero__flex .hero__left .hero_heading1
 {
    /* display: flex; */
    /* position: relative; */
    /* animation: heading 1s forwards; */
    /* color: var(--white) !important; */
    text-align: left;
    /* background-color: rgb(0, 255, 34); */
    /* text-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25); */
   
}
.hero .hero__flex .hero__left .hero_button {
    display: flex;
    position: relative;
    animation: heading 1s forwards;
    /* color: var(--white) !important; */
    background-color: var(--line);
    border-radius: 20px;
    text-align: center;
    /* text-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25); */
    box-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25); 
   
}
.hero .hero__flex .hero__left .featurBlockdown {
    display:flex;
    flex-direction:row;
    height: 80px;
    align-content:center  !important;
    align-items:center !important;
    justify-content: center !important;
    justify-items:center !important;
    background-color: transparent !important;
}
.hero .hero__flex .hero__left .featurBlockdown:hover {
    box-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 20px;
}
.hero .hero__flex .hero__left .featurBlockup {
    display:flex;
    flex-direction:row;
    height: 80px;
    align-content:center  !important;
    align-items:center !important;
    justify-content: center !important;
    justify-items:center !important;
    background-color: transparent !important;
    margin-top: 20px;
}
.hero .hero__flex .hero__left .featurBlockup:hover {
    box-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 20px;
}
.hero .hero__flex .hero__left .icon {
    /* width: 80px; */
    height: 100%;
    /* background-color: green; */
}



@keyframes heading {
    0% {
        left: -100px;
    }

    100% {
        left: 20px;
    }
}

.hero__flex>.hero__right {
    flex-basis: 60%;
}

@keyframes hero-img-animation-one {
    0% {
        bottom: -100px;
    }

    100% {
        bottom: 20px;
    }
}

@keyframes hero-img-animation-two {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(30deg);
    }
}

.second-section {
    height: auto;
    width: 100vw;
    /* background-image: linear-gradient(352deg,
            transparent,
            rgba(192, 220, 255, 0.5)),
        linear-gradient(94deg, #c9c9c9, #fff); */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
}
.second-section2 {
    height: 101vh;
    width: 100vw;
    /* background-image: linear-gradient(352deg,
            transparent,
            rgba(192, 220, 255, 0.5)),
        linear-gradient(94deg, #c9c9c9, #fff); */
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    overflow: hidden;
}

.second-section2  .notice_board_icon {
    width: 220px;
    position: absolute;
    left: 30px;
}

.second-section2  .note_icon {
    width: 160px;
    position: absolute;
    right: 40px;
}
.second-section2  .notice_board {
    background-image: url("../../images/icons/notice_board_bg.svg");
    width: 420px;
    background-repeat: no-repeat;
    min-height: 600px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;

}

    .second-section2  .notice_board .ul {
        list-style-type: circle;
        color: #303972;
        font-weight: 800;
  
    }
    .second-section2 .notice_board {
            h4 {
                color: #4f1c67;
                font-family: Lexend Deca;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-bottom: 5px !important;
                margin-bottom: 0px !important;
            }

            p {
                color: #000000b2;
                font-family: Open Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-bottom: 20px !important;
                
            }}
/* .second-section__flex {
    display: flex;
    align-items: center;
    gap: 40px;
} */

/* .second-section__flex>* {
    flex: 1;
} */

.second-section__left {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    /* background-color: aqua; */
}
.second-section__left .icon {
    height: 100%;
    /* width: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(24, 27, 27); */
    padding:  35px;
}

.second-section__right {
    width: 100%;
    height: 40vh;
    /* height: 100%; */
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
    /* padding-left: 30px; */
}
.second-section__right .content {
    width: 60%;
    /* height: 30%; */
   
}

.second-section__right .des_image {
    width: 40%;
    height: 100%;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
}
.second-section__right .des_image .icon {
    /* width: 100%; */
    height: 100%;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
}
.second-section__right2 {
    width: 100%;
    height: 101vh;
    /* height: 100%; */
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
    /* padding-left: 30px; */
}
.second-section__right2 .content {
    width: 60%;
    color: var(--primaryText);
    /* height: 30%; */
   
}
.second-section__right2 .content .buttonimg {
    /* width: 60%; */
    height: 80px;
    /* color: var(--primaryText); */
    /* height: 30%; */
    overflow: hidden;
   
}
.second-section__right2 .content .buttonimg .img{
    /* width: 60%; */
    /* height: 30px; */
    /* color: var(--primaryText); */
    /* height: 30%; */
    /* overflow: hidden; */
    height: 100%;
   
}
.second-section__right2 .des_image {
    width: 40%;
    height: 100%;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
}
.second-section__right2 .des_image .icon {
    /* width: 100%; */
    height: 100%;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
}



.second-section__rightui {
    width: 30%;
    /* height: 30%; */
    height: 70%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
}

.second-section__rightui .title {
    font-size: 50px !important;
    color: var(--white) !important;
    font-family: Montserrat, sans-serif;
    font-weight: 600 !important;
    padding-top: 30px;
}


.second-section__rightui .description1 {
    color:  var(--white) !important;
    margin-bottom: 14px;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.second-section__rightui p {
    color: var(--white) !important;
}


.second-section__rightui .icon {
    /* width: 90%; */
    height: 25%;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
    /* margin-left: 150px; */
}

.second-section .second-section__left .image{
    width: 100%;
    height: 75%;
    background-position: 42% 32%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 152%, auto;
    border-radius: 50%;
    margin-top: 0;
    margin-bottom: -33px;
    overflow: visible;
}

.second-section__right .title {
    font-size: 50px !important;
    color: var(--white) !important;
    font-family: Montserrat, sans-serif;
    font-weight: 600 !important;
}
.second-section__right2 .header {
    font-size: 35px !important;
    color: var(--primaryText) !important;
    font-family:    "Lexend Tera", sans-serif ;
    font-weight: 600 !important;
}
.second-section__right2 .title {
    font-size: 50px !important;
    color: var(--primaryText) !important;
    font-family:    "Lexend Tera", sans-serif ;
    font-weight: 600 !important;
}
.second-section__right2 .para {
    font-size: 18px !important;
    color:rgba(0, 0, 0, 0.788);
    font-family:   system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400 !important;
    line-height: 34px;
}

.second-section__right .description1 {
    color:  var(--white) !important;
    margin-bottom: 14px;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.second-section__right p {
    color: var(--white) !important;
}


/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

/* 
@media (max-width:600px) {
    .second-section__flex {
        flex-direction: column;
    }

    .second-section__left div {
        width: 200px;
        height: 200px;
        background-size: 100% auto;
        background-position: 100% 0%, 0px 0px;

    }

    .second-section__right h2 {
        font-size: 2em !important;
    }
} */

.feature {
    /* height: 67vh; */
    /* background-image: radial-gradient(circle closest-side, #fcf3f3, rgba(139, 139, 139, .26)), linear-gradient(rgba(255, 255, 255, .91), rgba(255, 255, 255, .91)), url("../../images/Optimum P_Logo-02.svg"); */
    background-position: 0 0, 0 0, 100% 0%;
    background-repeat: repeat, repeat, no-repeat;
    /* background-size: 30%; */
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
}

.feature h1 {
    margin-top: 20px;
    font-size: 28px;
    line-height: 44px;
}

.feature__heading {
    color: var(--primaryText) !important;
    text-align: center;
    margin-bottom: 48px;
    font-family:    "Lexend Tera", sans-serif ;
}

.feature__flex {
    display: flex;
    gap: 30px;
    /* flex-direction: column; */
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 30px;
    padding: 0 10px;
    /* background-color: #303972; */
}
.feature__content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.feature__item {
    display: flex;
    flex-basis: calc(50% - 30px);
    /* flex-direction: row; */
    background-color: transparent !important;
    /* padding: 10px !important; */
    padding-left: 32px !important;
    padding-top: 0px !important;
    /* border-radius: 20px !important; */
    /* box-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25) !important;  */
    height: 50vh !important;
    /* justify-content: center !important;
    align-content: center;
    align-items: center !important; */
    /* align-content: flex-end !important; */

    overflow: hidden;

    
    
}

.feature__item-heading {
    display: flex;
    /* gap: 15px; */
    /* margin-bottom: 15px !important; */
    align-items: center;
    /* background-color: #303972; */
    justify-content: center;

    flex-direction: column;
}

.feature__item-heading h3 {
    color: var(--primaryText) !important;
    font-size: 20px !important;
    margin-top: 0 !important;
    text-align: left;
}


.feature__item:hover .feature__item-desc{
    display: none;
    flex-direction: column;
    /* background-color: blue; */
}



.feature__item-desc p {
    font-size: 18px !important;
    color: var(--white) !important;
    text-align: left;
    font-weight: 400;
}
.feature__item-desc .icon {
    display: flex;
    font-size: 32px !important;
    color: var(--white) !important;
    /* text-align: right !important; ;
    align-items: flex-end !important; 
    background-color: aqua;
    justify-content: flex-end !important;
    align-self:flex-end !important; */
    /* font-weight: 400; */
    /* width: 100%; */
    position: absolute;
    bottom: 0;
    right: 0;
}

.feature__item-desc  {
    display: none;
    /* background-color: blue; */
    /* width: 100%; */
    /* align-content: flex-end !important; ;
    justify-content: end !important; 
    align-self: flex-end !important; ; */
    position: relative;
}
.feature__item-image {
    height: 40vh;
    /* width: 100%; */
    /* background-color: red; */
}

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */


/* @media (max-width:600px) {

    .feature__flex {
        flex-direction: column;

    }

    .feature__item {
        flex-basis: calc(25% - 30px);
    }

}

@media (max-width:1000px) {
    .feature__item {
        flex-basis: calc(50% - 30px);
    }
} */

/* 0   - 600px:    Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800px] is where our normal styles apply
  1800px +    :   Big Desktop */

@media (max-width: 900px) {

    .hero {
        /* width: 100%; */
        height: 50vh;
        /* background-image: linear-gradient(352deg,
                transparent,
                rgba(192, 220, 255, 0.5)),
            linear-gradient(94deg, #c9c9c9, #fff); */
            /* padding-top: 20px; */
            overflow: hidden;
            /* margin-bottom: 100px; */
            background-color: #303972;
    }

    .hero .hero__flex {
        display: flex;
        align-items: center;
        height: 60vh;
        background-size: 'cover';
        /* margin-top: 100px; */
        padding-bottom: 0px;
    }
    .hero .hero__flex .hero_slider_wrapper .swiper {
        /* width: 100%; */
            height: 100%;
            /* padding-top: 115px; */
        /* background-image: url("../../assets/nav_footer/images/footer_bg.png"); */
       
    
    }
    .modal_div {
        /* height:60vh; */
        /* width: 100%; */
        display: flex;
        justify-content: center !important;
        align-content:center !important;
        /* width:100%; */
        align-items:center !important;
    
        /* background-color: red; */
        
      }
      .modal .imgB{
        display: flex;
        width: 100% !important;
        height: auto !important;
        background-size: contain;
        justify-content: center;
        align-items: center;
        margin-top: -60px;
      }
    .second-section {
        height: auto !important;
        /* width: 100vw; */
        /* background-image: linear-gradient(352deg,
                transparent,
                rgba(192, 220, 255, 0.5)),
            linear-gradient(94deg, #c9c9c9, #fff); */
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 30px;
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
        background-color: #303972;
    }
    .second-section__right2 {
        flex-direction: column-reverse;
        width: 100%!important;
        height: 100vh !important;
    }
    .second-section__right2 .para {
        font-size: 12px !important;
        color:rgba(0, 0, 0, 0.788);
        font-family:   system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400 !important;
        line-height: 18px;
    }
    .second-section__right2 .title {
        font-size: 25px!important;
    }

    .second-section__right2 .header {
        font-size: 20px !important;
        margin-bottom: -20px;
        /* background-color: red; */

       
    }
    .second-section__right2 .des_image .icon {
        /* width: 100%; */
        /* margin-top: ; */
        height: 100%;
        /* height: 100%; */
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* padding: 44px; */
        /* background-color: red; */
        display: flex;
        margin-bottom: -100px;
    }
    .second-section__right2 .content {
        /* width: 60%; */
        color: var(--primaryText);
        height: 100%;
        /* background-color: #303972; */
        padding-bottom: 100px;

       
    }
    
    .hero__right {
        display: none;
    }

    .hero__left {
        /* background-image: url("../../images/hero-bg.png"); */
        width: 100%!important;
        background-position: 50%;
        background-size: cover;
        padding: 0 20px!important;
        justify-content: center !important;
    }

    .hero__left h1 {
        font-size: 33px!important;
        font-weight: 500!important;
    }

    .hero__left p {
        padding: 0 10px!important;
        width: 90%!important;
    }

    .hero__left .hero_button {
        width: 90%!important;
    }

    .hero__left .hero_button button {
        width: 100%!important;
    }
    .hero__left h3 {
        font-size: 56px !important;
        /* color: var(--primary) !important;
        color: white;
        /* font-weight: 900 !important; */
        /* background-color: blue; */
        /* text-align: center; */
        /* align-self: center !important;
        justify-content: center !important;
        padding-left: 10px !important;
        margin-top: 0px !important;
        align-content: center  !important;
        font-family: Montserrat, sans-serif;
        box-shadow: none !important; */ 
    }
    .feature__item-heading h3 {
        color: var(--primaryText) !important;
        font-size: 18px !important;
        margin-top: 10 !important;
        text-align: left;
        /* padding-bottom: 10px; */
    }
    
    .feature__item-image {
        height: 90%;
        width: 90%;
        /* background-color: red; */
    }

    .feature__content .buttonimg {
        /* width: 60%; */
        height: 80px !important;
        /* color: var(--primaryText); */
        /* height: 30%; */
        overflow: hidden;
        /* border-width: 0px !important; */
        background-color: transparent;
       
    }
    .feature__content .buttonimg .img{
        /* width: 60%; */
        /* height: 30px; */
        /* color: var(--primaryText); */
        /* height: 30%; */
        /* overflow: hidden; */
        height: 100%;
       
    }
    .second-section {
        flex-direction: column!important;
        height: 86vh;
    }

    .second-section .second-section__left {
        /* width: 100%; */
        height: 35%;
        padding: 5px 35px!important;
    }

    .second-section .second-section__left .image {
        height: 100%;
        background-position: 50% 34%, 0 0;
        margin-bottom: 0;
    }

    .second-section .second-section__right {
        width: 100%;
        height: 65%;
        padding: 10px!important;
        justify-content: center;
        /* background-color: blueviolet; */
    }
    .second-section .second-section__right2 {
        width: 100%;
        height: 65%;
        padding: 10px!important;
        justify-content: center;
    }

    .second-section__right .title {
        font-size: 25px!important;
    }
   
    .second-section__right .description-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-section__right .description-button button {
        width: 90%;
    }

    .feature {
        height: auto;
        background-position: 0 0, 0 0, 0% 100%;
        background-position: 0 0, 0 0, 100% 0%;
        background-repeat: repeat, repeat, no-repeat;
        /* background-size: 30%; */
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        /* background-color: #4f1c67; */
    }

   
    .feature__content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        align-items: center;
        padding-bottom: 30px;
    }
    
    .feature__item {
        display: flex;
        /* flex-basis: calc(50% - 30px); */
        /* flex-direction: row; */
        background-color: transparent !important;
        /* padding: 10px !important; */
        padding-left: 32px !important;
        padding-top: 0px !important;
        /* border-radius: 20px !important; */
        /* box-shadow: -5px 6px 4px rgba(0, 0, 0, 0.25) !important;  */
        height: auto !important;
        /* justify-content: center !important;
        align-content: center;
        align-items: center !important; */
        /* align-content: flex-end !important; */
    /* padding-bottom: 200px; */
        overflow: show;
        /* background-color: #303972; */
    
        
        
    }
    .feature__flex {
        display: flex;
        /* gap: 20px; */
        flex-direction: column;
        flex-wrap: wrap;
        flex-basis: 0%;
        /* margin-bottom: 30px; */
        /* padding: 0 10px; */
        padding-bottom: 20px;
        /* background-color: #303972; */
    }
    .second-section2  .notice_board {
        background-image: url("../../images/icons/notice_board_bg.svg");
        height: 20px;
      background-size: contain;
            width: 90%;
        background-repeat: no-repeat;
        min-height: 600px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding-top: 50px;
        padding-left: 30px;
    
    }
    
 .second-section2  .notice_board .ul {
        list-style-type: circle;
        color: #303972;
        font-weight: 800;
    }
    .second-section2  .notice_board .li {
            h4 {
                color: #4f1c67;
                font-family: Lexend Deca;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            p {
                color: #4f1c67;
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }}

    .second-section2  .notice_board_icon {
        width: 120px;
        position: absolute;
        left: 0px;
    }
    
    .second-section2  .note_icon {
        width: 100px;
        position: absolute;
        right: 0px;
        /* top: 100px; */
    }
    
    .second-section {
        padding: 0;
        height: 101vh;
        padding-bottom: 30px;
    }
    .second-section__right {
        flex-direction: column;
        width: 100%!important;
        height: 40vh !important;
    }
    .second-section__right .des_image {
        /* width: 40%; */
        height: 50%;
        /* height: 100%; */
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
        /* padding: 44px; */
        /* background-color: red; */
        display: flex;
    }
    .second-section__right .des_image .icon {
        /* width: 100%; */
        /* height: 100%; */
        /* height: 100%; */
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* padding: 44px; */
        /* background-color: red; */
        display: flex;
    }

    .second-section__right .content {
        width: 100%;
    }
  
    .second-section__right2 .des_image {
        /* width: 40%; */
        height: 50%;
        /* height: 100%; */
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
        /* padding: 44px; */
        /* background-color: red; */
        display: flex;
    }
    .second-section__right2 .des_image .icon {
        /* width: 100%; */
        /* height: 100%; */
        /* height: 100%; */
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* padding: 44px; */
        /* background-color: red; */
        display: flex;
        margin-bottom: -200px ;
    }

    .second-section__right2 .content {
        width: 100%;
    }

    /* .second-section__right .icon {
        object-fit: contain;
        width: 100%;
        height: 70%;
    } */
    
    .second-section2 {
        display: flex;
        /* height: auto; */
        /* justify-content: center;
        align-items: center; */
        flex-direction: column;
        padding-inline: 20px;
        /* height: 70vh; */
    }

.second-section__rightui {
    width: 100%;
    /* height: 30%; */
    height: 30vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
    /* background-color: aliceblue; */
}

.second-section__rightui .title {
    font-size: 25px !important;
    color: var(--white) !important;
    font-family: Montserrat, sans-serif;
    font-weight: 600 !important;
    padding-top: 30px;
}


.second-section__rightui .description1 {
    color:  var(--white) !important;
    /* margin-bottom: 14px; */
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.second-section__rightui p {
    color: var(--white) !important;
}


.second-section__rightui .icon {
    /* width: 90%; */
    /* height: 25%; */
    /* height: auto; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 44px; */
    /* background-color: red; */
    display: flex;
    /* margin-left: 150px; */
}
.second-section2 .second-section__left .icon{
    width: 45vh;
    /* height: 50vh */
    /* background-color: aliceblue; */
}
.hero__left h2 {
    font-size: 16px !important;
    /* color: var(--white) !important; */
    /* font-weight: 900 !important; */
    /* background-color: blue; */
    /* text-align: center; */
    /* align-self: center !important;
    justify-content: center !important;
    padding-left: 10px !important;
    margin-top: 0px !important;
    align-content: center  !important;
    font-family: Montserrat, sans-serif; */
}
.hero__left .features {
    
    display: none; 
    /* flex-direction: row; 
    width: 80%; 
    justify-content: space-between;  */
    /* background-color: aliceblue; */
}
.hero__left .features2 {
    
    display: none; 
    /* flex-direction: row; 
    width: 60%; 
    justify-content: space-between; 
    margin-Top: -20px; */
    /* background-color: aliceblue; */
}
}


@media (min-width: 900px) {
}

