.apply {
  /* height: 101vh; */
  /* background-image: '../../'; */
  /* background-color:rgba(70, 61, 163, 1); */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 10vh;
  padding-left: 95px;
  padding-right: 95px;
  display: flex;
  padding-bottom: 30px;
}
.apply .apply__right .input {
  color: red !important;

  /* background-color: red; */
}

.apply .apply__left {
  display: flex;
  /* width: 50%; */
  /* height: 100% !important; */
  flex: 1;
  /* background-image: none;
  background-repeat: repeat;
  background-size: auto; */
  flex-direction: column;
  /* flex: 0 auto; */
  /* justify-content: space-between; */
  align-self: auto;
  align-items: flex-start;
  padding: 20px;
  padding-top: 50px;
  
  border-radius: 5px;
  /* background-color: rgba(70, 61, 163, 1);  */
}
.apply .apply__left .img{
  height: 300px;
}
.apply .apply__left1 {
  width: 85%;
  /* height: vh!important; */
  /* background-image: none; */
  /* background-repeat: repeat;
  background-size: auto; */
  border-radius: 6px;
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  /* align-self: auto; */
  align-items: center;
  padding-left: 0;
  display: flex;
  background-color: white;
  padding: 10px;
  margin-top: 50px;
}
/* .apply__flex {
  display: flex;
  height: 100%;
  gap: 30px;
  flex: 1;
} */
.apply-flex{
  display: flex;
  flex-direction: column;
  flex: 1;
  /* padding-top: 50px; */
}
.apply-menu-item2 {
  /* text-align: center; */
  display: flex;
  color: var(--primaryText) !important;
  font-size: 16px;
  flex-direction: row;
   /* justify-content: center; */
  align-items: center;
  align-content: center;
  padding-top: 30px;
  /* background-color: red; */
}
.apply-menu-item2 .icon {
  /* text-align: center; */
  color:  var(--primaryText) !important;
  font-size: 30px;
   justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 20px;
  /* background-color: red; */
}

.apply .apply__right {
  width: 55%;
  /* height: 100%; */
  /* flex: 0 auto; */
  padding-top:30px ;
  padding-left: 10px;
}

.apply__left h1 {
  color:  var(--primaryText) !important;
  font-size: 28px !important;
}

.apply__left p {
  font-size: 16px;
  color:  var(--primaryText) !important;
}

.apply__btn {
  display: flex;
  justify-content: flex-end;
  /* width: 100vw; */
  /* color: red; */
  /* width: 100px; */
  align-self: baseline;
  align-content: baseline;

 
}

.apply__btn .button {
  width: 7vw;
  /* color: red; */
   background-color:var(--primary);
   border-radius: 5px;
}

/* 0   - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800px] is where our normal styles apply
1800px +    :   Big Desktop */


@media (max-width: 900px) {
  .apply {
    padding: 100px 0 80px 0;
    flex-direction: column;
  }

  .apply .apply__left {
    width: 100%;
    /* height: 30% !important; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* background-color: blanchedalmond; */
  }

  .apply .apply__right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .apply__flex {
    display: flex;
    height: 30%;
    /* gap: 30px; */
    flex: 1;
    background-color: antiquewhite;
  }

  .apply__left h1 {
    font-size: 30px !important;
  }

  .apply__left p {
    font-size: 20px !important;
    /* padding: 0 15px; */
  }

  .apply .apply__left1 {
    width: 75%;
    /* height: 70vh!important; */
    /* background-image: none; */
    /* background-repeat: repeat;
    background-size: auto; */
    /* border-radius: 6px; */
    flex-direction: column !important ;
    /* flex: 0 auto; */
    /* justify-content: center; */
    /* align-self: auto; */
    /* align-items: center; */
    /* padding-left: 0; */
    /* display: flex; */
    /* background-color: white; */
    /* padding: 10px; */
    /* margin-top: 50px; */
  }

  .apply .apply__right .form {
    width: 100%;
  }

  /* .apply__btn button {
    width: 100%;
  } */
  .apply__btn .button {
    width: 90%;
    /* color: red; */
     background-color:var(--primary);
     border-radius: 5px;
  }
  .apply__btn {
    display: flex;
    width: 100%;
    justify-content: center;
    /* width: 100vw; */
    /* color: red; */
    /* width: 100px; */
    /* align-self: baseline; */
    /* align-content: baseline; */
  
   
  }

  .apply-menu-item2 {
    /* text-align: center; */
    display: flex;
    color: var(--white) !important;
    font-size: 16px;
    flex-direction: row;
     /* justify-content: center; */
    align-items: center;
    align-content: center;
    padding-top: 0px;
    /* background-color: red; */
  }
  .apply-menu-item2 .icon {
    /* text-align: center; */
    color: var(--white) !important;
    font-size: 30px;
     justify-content: center;
    align-items: center;
    align-content: center;
    /* margin-right: 0px; */
    /* background-color: red; */
  }
}
