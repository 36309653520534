.ui.grid>[class*='four wide'].column {
  width: 30% !important;
}

.ui.grid>[class*='twelve wide'].column {
  width: 70% !important;
}

.product__top {
  display: flex;
  /* height: 52vh; */
  /* height: calc(100vh / 2); */
  /* background-position: 100% 50%, 0px 0px, 0px 0px;
  background-size: 960px, auto, auto;
  background-repeat: no-repeat, repeat, repeat; */
  height: 70vh;
  padding-top: 90px;
  flex-direction: row;
  overflow: hidden !important;
  /* background-color: red; */
}
.product__top .AcPic{
  width: 40%;

  height: 100%;
  margin-bottom: -90px;
  align-self: flex-end;

} 
.product__top .icon1{
   width: 150px;

  
  align-self:flex-start;

}
.product__top .icon2{
  /* width: 0%; */

  height: 100%;
  align-self: flex-end;

}

.product__top h1 {
  /* height: calc(100vh / 2) !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 43px !important;
  color: var(--primaryText) !important;
  font-family:    "Lexend Tera", sans-serif !important;
  margin-left: -200px !important;
  align-self: flex-start;
  margin-top: 100px !important;
}

.product__bottom {
  /* padding-top: 100px !important; */
  /* height: auto; */
  /* padding-bottom: 30px !important; */
  border-width: 0px !important ;
  
}
.product__bottom .main_section {
  width: 100%;
  min-height: 50vh;
  margin-top: -5px;
  background-image: url("../../images/BG2.png");}

  .product__bottom .main_section  .tabs_wrapper {
    width: 100%;
    padding-top: 150px;
    border-bottom: 6px solid rgba(208, 94, 24, 0.76);
    box-shadow: 0px 4px 10px #888888;
    position: relative;
    .left {
      justify-content: left;
    }
    .center {
      justify-content: center;
    }
    .right {
      justify-content: right;
    }
  }

    .product__bottom .main_section  .tabs_wrapper .tabs {
     
      display: flex;
      gap: 10px;
      width: 90%;
      margin-left: 5%;
      position: absolute;
      top: 91px;
      /* padding-bottom: 6px; */
      span {
        color: #000;
        text-align: center;
        font-family: Architects Daughter;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: normal;
        padding: 10px 40px;
        border: 6px solid transparent;
        position: relative;
        padding-bottom: 10px;
      }
      .active {
        border: 6px solid rgba(208, 94, 24, 0.76);
        box-shadow: inset 0px 4px 10px #888888;
        border-bottom: 6px solid #a1238e;
        padding-bottom: 0px !important;
      }}
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 .img  {
        width: 50%;
        /* height: 10% !important;/ */
        display: flex;
        /* flex-direction: row; */
        /* margin-right: 100px; */
        align-self: center;
        align-content: center;
        /* background-color: red; */
      }
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 .img .image2 {
        width: 100%;
        /* height: 10% !important;/ */
        display: flex;
        /* flex-direction: row; */
        /* margin-right: 100px; */
        align-self: center;
        align-content: center;
        /* background-color: red; */
      }
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 {
        width: 100%;
        /* height: 10% !important;/ */
        display: flex;
        /* flex-direction: row; */
        /* margin-right: 100px; */
        align-self: center;
        align-content: center;
        /* background-color: red; */
      }
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 .content2 {
        width: 60% !important;
        /* height: 10% !important;/ */
        display: flex;
        flex-direction: column;
        /* margin-right: 100px; */
        align-self: center;
        align-content: center;
        padding-left: 20px;
        /* background-color: red; */
      }
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 p {
        color: #000;
        /* font-family: Open Sans; */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        /* background-color: blue; */
      }
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_3 p {
        color: #000;
        /* font-family: Open Sans; */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
      }
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_3 h3 {
        color: #4f1c67;
        font-family: Lexend Tera; 
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .product__bottom .main_section .pre_primary_wrapper {
        width: 90%;
        margin-left: 5%;
        position: relative;
        padding-bottom: 50px;
        .a_image {
          position: absolute;
          right: 0px;
        }
        .a2_image {
          position: absolute;
          right: -40px;
          width: 45%;
        }
        .pre_primary_section_1 {
          display: flex;
          flex-direction: row;
          margin-top: 30px;
          .content {
            width: 50%;
            h3 {
              color: #4f1c67;
              font-family: Lexend Tera; 
              font-size: 46px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            p {
              /* color: #000; */
              font-family: Open Sans;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 34px;
            }
          }
          .img {
            width: 50%;
            position: relative;
            .pre_primary_1 {
              position: absolute;
              bottom: 0px;
            }
           
          }
        }}

        .product__bottom .main_section .primary_wrapper {
          width: 90%;
        margin-left: 5%;
        margin-bottom: -20px;
        padding-bottom: 10%;
        position: relative;
        .plus {
          position: absolute;
          right: 40%;
          top: 6%;
        }
        .minus {
          position: absolute;
          right: 30%;
          top: 8%;
        }
        .abacus {
          position: absolute;
          right: 20%;
          top: 13%;
        }
        .pink_a {
          position: absolute;
          right: 30%;
          bottom: 35%;
        }
        .a_letter {
          position: absolute;
          right: 40%;
          bottom: 40%;
        }
        .computer {
          position: absolute;
          right: 20%;
          bottom: 17%;
        }
        .content {
          width: 60%;
        }
        h3 {
          color: #4f1c67;
          font-family: Lexend Tera;
          font-size: 46px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 10px;
        }
        h4 {
          color: #8a609e;
          font-family: Open Sans;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          margin: 10px;
        }
        p {
          color: #000;
          font-family: Open Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px;
          /* 188.889% */
        }
      
          }
      /* .product__bottom .main_section  .tabs_wrapper */

/* ////// */

@keyframes product-img {
  0% {
    right: 0px;
  }

  100% {
    right: 100px;
  }
}

.tab__flex__images img {
  flex-direction: column;
  width: 100px;
  padding: 5px;
  object-fit: cover;
}

.tab__btn__flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  width: 50%;
}
.tab__btn__flex2 {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  width: 50%;
}
.tab__btn__flex .icon{
 /* height: 100%; */
 width: 70%;
}
.tab__btn__flex2 .icon2{
  /* height: 100%; */
  width: 40%;
  margin-bottom: 100px;
  /* margin-left: -200px; */
  border-radius: 10%;
  margin-right: -100px; 
 }

 @media  (max-width: 900px){
  .product__top {
    height: 50vh;
    overflow: hidden;
  }

  .product__top h1 {
    font-size: 30px!important;
    height: 25vh!important;
    /* margin-left: -200px !important; */
    align-self: center;
    
    /* margin-bottom: -100px; */
    /* padding-bottom: 200px; */

    margin-top: -70px !important;
  }
  .product__top .AcPic{
    width: 80%;
    margin-bottom: -50px;
    margin-left: -10px;
    height: auto;
    align-self: flex-end;
  
  }
  .product__top .icon2{
    /* width: 0%; */
  margin-left: -160px;
    height: 100%;
    align-self: flex-end;
    margin-bottom: -100px;
  
  }
  .product__top .icon1{
    width: 150px;
    margin-left: -80px;
   
   align-self:flex-start;
 
 }

 .product__bottom .main_section  .tabs_wrapper {
  width: 100%;
  padding-top: 150px;
  border-bottom: 6px solid rgba(208, 94, 24, 0.76);
  box-shadow: 0px 4px 10px #888888;
  position: relative;
  .left {
    justify-content: left;
  }
  .center {
    justify-content: center;
  }
  .right {
    justify-content: right;
  }
}
.product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 {
  width: 100%;
  /* height: 10% !important;/ */
  display: flex;
  flex-direction: column;
  /* margin-right: 100px; */
  align-self: center;
  align-content: center;
  /* background-color: red; */
}
.product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 .img  {
  width: 100%;
  /* height: 10% !important;/ */
  display: flex;
  /* flex-direction: row; */
  /* margin-right: 100px; */
  align-self: center;
  align-content: center;
  /* background-color: red; */
}
.product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 .img .image2 {
  width: 100%;
  /* height: 10% !important;/ */
  display: flex;
  /* flex-direction: row; */
  /* margin-right: 100px; */
  align-self: center;
  align-content: center;
  /* background-color: red; */
}
.product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 {
  width: 100%;
  /* height: 10% !important;/ */
  display: flex;
  /* flex-direction: row; */
  /* margin-right: 100px; */
  align-self: center;
  align-content: center;
  /* background-color: red; */
}
.product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 .content2 {
  width: 100% !important;
  /* height: 10% !important;/ */
  display: flex;
  flex-direction: column;
  /* margin-right: 100px; */
  align-self: center;
  align-content: center;
  padding-left: 20px;
  /* background-color: red; */
}
.product__bottom .main_section .pre_primary_wrapper .pre_primary_section_2 p {
  color: #000;
  /* font-family: Open Sans; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  /* background-color: blue; */
}
  .product__bottom .main_section  .tabs_wrapper .tabs span {
  color: #000;
  text-align: center;
  font-family: Architects Daughter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: normal;
  padding: 0px 14px;
  border: 6px solid transparent;
  position: relative;
  /* padding-bottom: 4px; */
  /* width: 100%; */
  min-width: 30%;
  }
  .product__bottom .main_section  .tabs_wrapper .tabs {
     
    display: flex;
    gap: 10px;
    width: 90%;
    margin-left: 5%;
    position: absolute;
    top: 91px;
    /* padding-bottom: 6px; */
    
      }
.product__bottom .main_section  .tabs_wrapper .tabs .active {
        border: 6px solid rgba(208, 94, 24, 0.76);
        box-shadow: inset 0px 4px 10px #888888;
        border-bottom: 6px solid #a1238e;
        padding-bottom: 0px !important;
      }

  .product__bottom {
    height: auto;
    margin-top: 0;
    width: auto!important;
    padding: 0!important;
    /* overflow-x: scroll; */
  }
 
  .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_1 .content p {
    /* color: #000; */
    /* font-family: Open Sans; */
    font-size: 18px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 24px;
    z-index: 10;
    width: 90%;
  }

  .product__bottom .main_section .pre_primary_wrapper .a_image {
    /* position: absolute; */
    /* left: 0px; */
    /* justify-self: flex-end; */
    /* background-color: #000; */
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: 200px;
    /* z-index: 01; */
    width: 35%;
    /* top:-20px; */
    /* padding-top: 90px; */
  } 
  .product__bottom .main_section .pre_primary_wrapper .a2_image {
    position: absolute;
    right: 0px;
    width: 95%;
    top:-40px;
  }
  .product__bottom .main_section .pre_primary_wrapper .img .pre_primary_2 {
    width: 70%;
    /* margin-right: 100px; */
    justify-content: center;
    align-content: center;
    align-self: center;
  }
  .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_1 {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    .content {
      width: 100%;
      margin-top: 200px;
      h3 {
        color: #4f1c67;
        font-family: Lexend Tera; 
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      /* p {
        color: #000;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        z-index: 10;
      } */
    }
   
  }
  .product__bottom .main_section .pre_primary_wrapper {
    width: 90%;
    height: auto;
    /* display: flex; */
    /* flex-direction: row; */
    margin-left: 5%;
    margin-right: 5%;
    position: relative;
    padding-bottom: 50px;
    

     }
    
      .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_1  p {
      /* color: #000; */
      /* font-family: Open Sans; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
     .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_1  h3 {
      color: #4f1c67;
      font-family: Lexend Tera; 
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-top: 20px;
    }
    .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_3 p {
      /* color: #000; */
      /* font-family: Open Sans; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .product__bottom .main_section .pre_primary_wrapper .pre_primary_section_3 h3 {
      color: #4f1c67;
      font-family: Lexend Tera; 
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
    .product__bottom .main_section .primary_wrapper p {
      /* color: #000; */
      /* font-family: Open Sans; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .product__bottom .main_section .primary_wrapper h3 {
      color: #4f1c67;
      font-family: Lexend Tera; 
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
    .product__bottom .main_section .primary_wrapper {
      width: 90%;
    margin-left: 5%;
    margin-bottom: -20px;
    padding-bottom: 10%;
    position: relative;
    .plus {
      position: absolute;
      right: 20%;
      top: 1%;
    }
    .minus {
      position: absolute;
      right: 10%;
      top: 10%;
    }
    .abacus {
      position: absolute;
      right: 5%;
      top: 13%;
    }
    .pink_a {
      position: absolute;
      right: 10%;
      bottom: 35%;
    }
    .a_letter {
      position: absolute;
      right: 20%;
      bottom: 40%;
    }
    .computer {
      position: absolute;
      right: 7%;
      bottom: 17%;
    }
    .content {
      width: 60%;
    }
    h3 {
      color: #4f1c67;
      font-family: Lexend Tera;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px;
    }
    h4 {
      color: #8a609e;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 10px;
    }
    p {
      color: #000;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 188.889% */
    }
  
      }


  .product__bottom .ui.tabular.menu .item.heading {
    width: 100%!important;
  }

  .product__bottom .ui.tabular.menu .item {
    width: 45% !important;
    display: flex;
    justify-content: flex-start;
  }

  .ui .attached .segment {
    height: auto!important;
  }

  .product__bottom .tab {
    height: auto!important;
  }

  .product__bottom .ui.grid {
    flex-direction: column;
    width: auto!important;
  }

  .ui.grid>[class*='four wide'].column {
    width: 100% !important;
  }

  .ui.grid>[class*='twelve wide'].column {
    width: 100%!important;
  }

  .product__bottom .twelve .ui.bottom.attached.segment {
    width: auto!important;
    padding: 0!important;
    overflow: hidden;
  }

  .product__bottom .menu {
    display: flex;
    flex-direction: row!important;
    overflow-y: auto;
    width: 100vw!important;
    /* scrollbar-width: none!important; */
  }

  .bms-section {
    /* flex-direction: column!important; */
    /* overflow-x: scroll; */
    /* width: 400vw!important; */
    /* background-image: linear-gradient(355deg, transparent, rgba(192, 220, 255, 0.5)), linear-gradient(94deg, #c9c9c9, #fff) !important; */
    /* margin-top: -2vh; */
    /* margin-bottom: -2vh; */
    margin-left: 3vh;
    height: 51vh!important;
    /* overflow: hidden; */
  }

  .product__bottom .bms-section {
    height: auto !important;    
  }

  .product__bottom .bms-section .bms-item .title {
    font-size: 22px !important;
  }

  .bms-item {
    /* width: 80vw!important; */
    padding-top: 1vh;
    /* margin-top: 2vh; */
    flex-direction: column!important;
  }

  .tab {
    height: auto!important;
    overflow-x: auto;
    min-height: 50vh!important;
    overflow-y: hidden;
    padding-bottom: 2vh;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: 2vh;
    scrollbar-width: 0;
  }

  .tab .ui.large.header {
    margin-left: 4vh!important;
  }

  .tab__btn__flex {
    width: auto !important;
    height: 300px;
  }

  .tab__btn__flex .icon {
    height: 75% !important;
    width: auto;
  }

  .tab .tab__flex {
    margin-left: 4vh!important;
    height: 40vh!important;
  }

  .product .product-description {
    height: 220px!important;
  }

  .product .battery-swapping {
    height: 220px!important;
  }

  .product .beta-schematic {
    height: 220px!important;
  }
}

@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */

  
}